import React from 'react';
import { ReactSVG } from 'react-svg';
import { LinkTo } from '@dragonchain-dev/dragon-kit';

const { IMAGES_URI } = process.env;
const sdkDartSVG = `${IMAGES_URI}/icons/dart.svg`;
const sdkNodeSVG = `${IMAGES_URI}/icons/nodejs.svg`;
const sdkPythonSVG = `${IMAGES_URI}/icons/python.svg`;
const sdkGoSVG = `${IMAGES_URI}/icons/go.svg`;

const CodeIcons = () => {
  return (
    <div className="icons">
      <LinkTo blank type="link" url="https://github.com/dragonchain/dragonchain-sdk-javascript">
        <ReactSVG src={sdkNodeSVG} />
      </LinkTo>
      <LinkTo blank type="link" url="https://github.com/dragonchain/dragonchain-sdk-python">
        <ReactSVG src={sdkPythonSVG} />
      </LinkTo>
      <LinkTo blank type="link" url="https://github.com/dragonchain/dragonchain-sdk-go">
        <ReactSVG src={sdkGoSVG} />
      </LinkTo>
      <LinkTo blank type="link" url="https://github.com/dragonchain/dragonchain-sdk-dart">
        <ReactSVG src={sdkDartSVG} />
      </LinkTo>
    </div>
  );
};

export default CodeIcons;
