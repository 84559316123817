import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Header = props => {
  const { children, ...rest } = props;

  return (
    <div className="thead" {...rest}>
      <div className="tr">{children}</div>
    </div>
  );
};

const HeaderItem = props => {
  const { sortable, sortableDataType, viewPoint, children, ...rest } = props;

  const itemClass = cx('th', viewPoint, sortable && 'is-sortable');

  return (
    <div className={itemClass} data-sort-as={sortableDataType} {...rest}>
      {children}
    </div>
  );
};

export { Header, HeaderItem };

// HEADER
// TODO: add custom prop-type to check if type id HeaderItem
Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

// HEADER ITEM
HeaderItem.defaultProps = {
  sortable: false,
  sortableDataType: '',
  viewPoint: '',
};

HeaderItem.propTypes = {
  children: PropTypes.string.isRequired,
  sortable: PropTypes.bool,
  sortableDataType: PropTypes.oneOf(['integer', 'string', '']),
  viewPoint: PropTypes.oneOf(['phone-hide', 'tablet-or-higher', '']),
};
