import React from 'react';
import PropTypes from 'prop-types';
import NumAbbr from 'number-abbreviate';
import { LinkTo } from '@dragonchain-dev/dragon-kit';
import Table from './Table';
import { Header, HeaderItem } from './Header';
import { Body, RowItem, Row } from './Row';

const { IMAGES_URI } = process.env;
const recovIcon = `${IMAGES_URI}/slumber/recovery_icon.svg`;
const saleIcon = `${IMAGES_URI}/slumber/gold_jojo_coin.svg`;
const jojoIcon = `${IMAGES_URI}/slumber/jojo-treasure-small.png`;
const exchangeIcon = `${IMAGES_URI}/slumber/exchangeIconDark.png`;

const Leaderboard = ({ data, ethereumAddress, ...rest }) => {
  const selected = data.filter(r => r.address === ethereumAddress)[0];
  const numAbbr = new NumAbbr();
  const etherscan = 'https://etherscan.io/token/0x419c4db4b9e25d6db2ad9691ccb832c8d9fda05e?a=';

  const rank = (num, address) => (
    <LinkTo url={`${etherscan}${address}`} type="blank">
      {num}
    </LinkTo>
  );

  const daysToSurpass = (rowAddress, rowSs, rowBal) => {
    const { ss, bal, address } = selected;
    const days = (rowSs - ss) / (bal - rowBal);
    if (address !== rowAddress && days > 0) {
      return Number(days).toLocaleString(undefined, { maximumFractionDigits: 2 });
    }
    return '';
  };

  const badges = (flags, recovered) => {
    const { dc, ex, sale } = flags;
    return (
      <>
        {dc && <img src={jojoIcon} alt="dragonchain" />}
        {ex && <img src={exchangeIcon} alt="exchange" />}
        {sale && <img src={saleIcon} alt="token sale" />}
        {recovered > 0 && <img src={recovIcon} alt="recovery" />}
      </>
    );
  };

  const rows = data.map(r => (
    <Row key={r.lb} selected={ethereumAddress === r.address} id={`row-${r.lb}`}>
      <RowItem>{rank(r.lb, r.address)}</RowItem>
      <RowItem>{numAbbr.abbreviate(r.bal, 1)}</RowItem>
      <RowItem>{Math.round(r.ss).toLocaleString()}</RowItem>
      <RowItem>{Math.round(r.avg).toLocaleString()}</RowItem>
      {selected && <RowItem>{daysToSurpass(r.address, r.ss, r.bal)}</RowItem>}
      <RowItem className="badges">{badges(r.flags, r.recovered > 0)}</RowItem>
    </Row>
  ));

  return (
    <>
      <Table className="leaderboard" {...rest}>
        <Header>
          <HeaderItem sortable sortableDataType="integer" viewPoint="phone-hide">
            Rank
          </HeaderItem>
          <HeaderItem>Balance</HeaderItem>
          <HeaderItem>TIME</HeaderItem>
          <HeaderItem>Avg. Days</HeaderItem>
          {selected && <HeaderItem>Days to Surpass</HeaderItem>}
          <HeaderItem>Badges</HeaderItem>
        </Header>
        <Body>{rows}</Body>
      </Table>
      {/* <div id="legend" className="legend">
        <div className="content">
          <img src={jojoIcon} alt="dragonchain" />
          <div className="title">Dragonchain Wallet</div>
        </div>
        <div className="content">
          <img src={exchangeIcon} alt="exchange" />
          <div className="title">Exchange Wallet</div>
        </div>
        <div className="content">
          <img src={saleIcon} alt="token sale" />
          <div className="title">Token Sale</div>
        </div>
        <div className="content">
          <img src={recovIcon} alt="recovery" />
          <div className="title">TIME Recovered</div>
        </div>
      </div> */}
    </>
  );
};

export default Leaderboard;

Leaderboard.defaultProps = { ethereumAddress: '' };

Leaderboard.propTypes = {
  ethereumAddress: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      avg: PropTypes.number.isRequired,
      bal: PropTypes.number.isRequired,
      flags: PropTypes.shape({
        dc: PropTypes.bool.isRequired,
        ex: PropTypes.bool.isRequired,
        sale: PropTypes.bool.isRequired,
      }).isRequired,
      lb: PropTypes.number.isRequired,
      recovered: PropTypes.number.isRequired,
      ss: PropTypes.number.isRequired,
    }),
  ).isRequired,
};
