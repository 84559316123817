import React from 'react';
import { Column, Detail, LinkTo, Row } from '@dragonchain-dev/dragon-kit';

import Den from '../assets/images/icons/den.svg';
import Shield from '../assets/images/icons/shield.svg';
import Eternal from '../assets/images/icons/eternal.svg';
import Factor from '../assets/images/icons/factor.svg';
import Loyalty from '../assets/images/icons/loyalty.svg';
import Lyceum from '../assets/images/icons/lyceum.svg';
import Contests from '../assets/images/icons/contests.svg';
import Blockchain from '../assets/images/icons/blockchain-b.svg';
import Audit from '../assets/images/icons/audit.svg';

const SolutionRows = () => {
  return (
    <Row gap="large" columnGap="medium" span={4} tabletPortraitSpan={6}>
      <Column>
        <Detail
          title="Anti-Fraud &amp; Compliance"
          icon={<Audit />}
          footer={
            <LinkTo type="route" to="/business/anti-fraud">
              Learn More
            </LinkTo>
          }
        >
          Fintech suite to prove regulatory compliance and data records. Provide selective transparency to
          your customers, regulators, or any third party.
        </Detail>
      </Column>

      <Column>
        <Detail
          title="Decentralized Identity"
          icon={<Factor />}
          footer={
            <LinkTo type="route" to="/business/factor">
              Learn More
            </LinkTo>
          }
        >
          Factor is a GDPR and CCPA capable identity management platform to increase security, reduce risk of
          customer PII storage, and simplify authentication.
        </Detail>
      </Column>
      <Column>
        <Detail
          title="Provably Fair Systems"
          icon={<Contests />}
          footer={
            <LinkTo type="route" to="/business/provable-contests">
              Learn More
            </LinkTo>
          }
        >
          Provide measurable and immutable proof that a contest, raffle, sweepstakes, or eSports tournament is
          fair to all participants.
        </Detail>
      </Column>
      <Column>
        <Detail
          title="Loyalty &amp; Rewards"
          icon={<Loyalty />}
          footer={
            <LinkTo type="route" to="/business/loyalty-and-rewards">
              Learn More
            </LinkTo>
          }
        >
          Tokenize your reward program with retention and loyalty incentive features for improved customer
          engagement.
        </Detail>
      </Column>
      <Column>
        <Detail
          title="Learning Management System"
          icon={<Lyceum />}
          footer={
            <LinkTo type="route" to="/business/lyceum">
              Learn More
            </LinkTo>
          }
        >
          Lyceum provides traceable proof of course progress, completion, scoring, continuing education units,
          and certification. Bring your own content and customize your data.
        </Detail>
      </Column>
      <Column>
        <Detail
          title="Archival &amp; Proof Systems"
          icon={<Eternal />}
          footer={
            <LinkTo type="route" to="/business/eternal">
              Learn More
            </LinkTo>
          }
        >
          Capture, sign, timestamp, and watermark documents for contract transparency with on chain proof.
        </Detail>
      </Column>
      <Column>
        <Detail
          title="Decentralized Social Media"
          icon={<Den />}
          footer={
            <LinkTo type="route" to="/business/den">
              Learn More
            </LinkTo>
          }
        >
          Build a branded community to engage with your customers. Forums are completely under your control
          and incentivize your most loyal customers to manage your community.
        </Detail>
      </Column>
      <Column>
        <Detail
          title="Proof &amp; Privacy Systems"
          icon={<Shield />}
          footer={
            <LinkTo type="route" to="/business/proof-systems">
              Learn More
            </LinkTo>
          }
        >
          Any sort of chain of custody, beyond just evidence, has a whole new dimension with blockchain
          enabled Proof Systems.
        </Detail>
      </Column>
      <Column>
        <Detail
          title="Enterprise NFT"
          icon={<Blockchain />}
          footer={
            <LinkTo type="route" to="/business/enterprise-nft">
              Learn More
            </LinkTo>
          }
        >
          From tracking supply chain information, to settling money transfers, to controlling access to data between organizations, Enterprise NFTs will revolutionize asset management and ownership.
        </Detail>
      </Column>
    </Row>
  );
};

export default SolutionRows;
