import React from 'react';
import { DragonKit, Header, Footer, LinkTo } from '@dragonchain-dev/dragon-kit';
import { Link } from 'gatsby';

const Layout = ({ children }) => {
  const { IMAGES_URI } = process.env;

  return (
    <DragonKit Link={Link} theme="dark" layout="full">
      <Header logo={`${IMAGES_URI}/brand/dragonchain.svg`} />
      <main>{children}</main>
      <Footer
        logo={`${IMAGES_URI}/brand/jojo.png`}
        tagline="The Blockchain of Blockchains."
        entity="Dragonchain, Inc"
      >
        <p>
          The comments, views, and opinions expressed are those of the author and do not necessarily reflect
          the official policy or position of the Walt Disney Company, Disney Connected and Advanced
          Technologies, or any affiliated companies.{' '}
          <LinkTo type="route" url="/terms">
            Terms of service.
          </LinkTo>
        </p>
      </Footer>
    </DragonKit>
  );
};

export default Layout;
