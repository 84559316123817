import React from 'react';
import cx from 'classnames';
import Form from 'react-jsonschema-form';
import { Button } from '@dragonchain-dev/dragon-kit';
import { useSelector, useDispatch } from 'react-redux';
import md5 from 'blueimp-md5';
import { newEntry } from '../store/contestSlice';
import { epochToModifiedFormat } from '../utils/dateUtils';

const ContestRowActive = ({ name, termsLink }) => {
  const dispatch = useDispatch();
  const contest = useSelector(state => state.contest.contests[name]);
  const error = useSelector(state => state.error);
  const { count, schema, entry } = contest || {};

  const Error = () => {
    if (!error || error.id !== name) return <></>;
    return (
      <div className="form-error">
        <div className="inner">{error.message}</div>
      </div>
    );
  };

  const Terms = () => {
    if (!termsLink || entry.transactionId) return <></>;
    return (
      <div className="form-note">
        <div className="inner">
          By submitting this form, you are agreeing to the&nbsp;
          <a href={termsLink} target="_blank">
            contest rules
          </a>
        </div>
      </div>
    );
  };

  const Transaction = () => {
    if (!entry.transactionId) return <></>;
    return (
      <div key="2" className="box-row-content">
        <div>
          <p>Your entry has been received, transaction ID:</p>
          <p>
            <b>{entry.transactionId}</b>
          </p>
        </div>
      </div>
    );
  };

  const handleSubmit = ({ formData }, e) => {
    e.preventDefault();
    dispatch(newEntry({ form: name, fields: formData }));
  };

  const handleChange = ({ formData }) => {
    if (formData.email) formData.emailHash = md5(formData.email);
  };

  if (!contest) return <></>;
  return (
    <>
      <div key="0" className="box-row-content">
        <div>
          <p>
            Entries <b>{count}</b>
          </p>
        </div>
        <div>
          <p>
            Entry Deadline <b>{epochToModifiedFormat(schema.stop)}</b>
          </p>
        </div>
      </div>
      <Transaction />
      {contest && schema && (
        <div key="1" className="box-row-content">
          <Form
            className={cx('rjsf', !!entry.transactionId && 'success')}
            onSubmit={handleSubmit}
            onChange={handleChange}
            schema={schema.jsonschema}
            formData={entry.entry}
            uiSchema={schema.uischema}
            disabled={!!entry.transactionId}
          >
            <Terms />
            <div className="actions">
              <Button
                className={cx(entry.transactionId ? 'button hidden' : undefined)}
                type="submit"
                hover="blue-dark"
                disabled={!!entry.transactionId}
              >
                Submit
              </Button>
              <Error />
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default ContestRowActive;
