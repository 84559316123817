import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PillButton } from '@dragonchain-dev/dragon-kit';
import Form from 'react-jsonschema-form';
import md5 from 'blueimp-md5';
import proofScript from '../utils/proofScript';

import ShowSvg from '../assets/images/icons/show.svg';
import HideSvg from '../assets/images/icons/hide.svg';
import BitcoinSvg from '../assets/images/icons/bitcoin.svg';
import EthereumSvg from '../assets/images/icons/ethereum.svg';
import { epochToModifiedFormat } from '../utils/dateUtils';

const Toggle = ({ text, open, onClick }) => (
  <PillButton type="button" onClick={onClick}>
    <div className="icon">{open ? <HideSvg /> : <ShowSvg />}</div>
    {open ? 'Hide' : 'Show'} {text}
  </PillButton>
);

const Networks = ({ blockHashes }) => (
  <div className="blocks">
    <div className="label">View Blocks</div>
    <div key="networks" className="networks">
      <a target="_blank" rel="noreferrer" href={`https://www.blockchain.com/btc/block/${blockHashes.btc}`}>
        <BitcoinSvg />
      </a>
      <a target="_blank" rel="noreferrer" href={`https://etherscan.io/block/${blockHashes.eth}`}>
        <EthereumSvg />
      </a>
    </div>
  </div>
);

const MyEntry = ({ display, jsonschema, uischema, entry, transactionId }) => {
  if (!display) return <></>;

  return (
    <>
      <div key="entry-id" className="box-row-content">
        <Form disabled schema={jsonschema} formData={entry} uiSchema={uischema}>
          <button type="submit" hidden>
            hide
          </button>
        </Form>
      </div>
      <div key="2" className="box-row-content">
        <div>
          <p>Your entry has been received, transaction ID:</p>
          <p>
            <b>{transactionId}</b>
          </p>
        </div>
      </div>
    </>
  );
};
const Proof = ({ display, entries, hashes, name }) => {
  if (!display) return <></>;

  return (
    <div key="blocks" className="blocks box-row-content">
      <div className="proof">
        <div className="block-hashes">
          <div className="block-hash">
            <BitcoinSvg />
            <div className="value">{hashes[0]}</div>
          </div>
          <div className="block-hash">
            <EthereumSvg />
            <div className="value">{hashes[1]}</div>
          </div>
        </div>
        <pre>
          <div className="lang">Python Proof</div>
          <code>{proofScript(entries, hashes, name)}</code>
        </pre>
      </div>
    </div>
  );
};

const ContestRowCompleted = ({ name, winnerKey }) => {
  const [email, setEmail] = useState('');
  const [showProof, setShowProof] = useState(false);
  const [showEntry, setShowEntry] = useState(false);
  const contest = useSelector(state => state.contest.contests[name]);

  if (!contest.winner.selected) {
    return (
      <div key="not-selected" className="box-row-content">
        Contest ended {epochToModifiedFormat(contest.schema.stop)}. The winner has not yet not selected. Check
        back soon.
      </div>
    );
  }

  return (
    <>
      <div key="winner" className="winner box-row-content">
        <div className="entry">
          <div className="label">
            <b>Winner Selected</b>&nbsp;({contest.count} entries)
          </div>
          <div className="value">
            <span>Winner:</span> {contest.winner.entry.data[winnerKey]}
          </div>
        </div>
        <Networks blockHashes={contest.winner.blockHashes} />
        <div className="actions">
          <Toggle
            text="Proof"
            open={showProof}
            onClick={() => {
              setShowProof(!showProof);
              setShowEntry(false);
            }}
          />
          {!!contest.entry.transactionId && (
            <Toggle
              text="My Entry"
              open={showEntry}
              onClick={() => {
                setShowEntry(!showEntry);
                setShowProof(false);
              }}
            />
          )}
        </div>
      </div>
      <Proof display={showProof} entries={contest.count} hashes={contest.winner.blockHashArray} name={name} />
      <MyEntry
        display={showEntry}
        entry={contest.entry.entry}
        transactionId={contest.entry.transactionId}
        uischema={contest.schema.uischema}
        jsonschema={contest.schema.jsonschema}
      />
      <div key="check-winner" className="box-row-content">
        <form className="rjsf" onSubmit={e => e.preventDefault()}>
          <legend id="root__title">See if you are the winner</legend>

          <div className="form-group field field-string">
            <fieldset>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <input
                required
                name="email"
                type="email"
                className="form-control"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Email address..."
              />
            </fieldset>
          </div>

          {email && (
            <div className="form-note">
              <div className="inner">
                {md5(email) !== contest.winner.entry.data[winnerKey]
                  ? 'Thanks for entering. Better luck next time. 🤞'
                  : '🎉 You could be the winner! Contact us for final verification.'}
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default ContestRowCompleted;
