const proofScript = (entries, hashes, name) => `import hashlib
import requests
          
selection_hash = hashlib.sha256()
block_hashes = [
  "${hashes[0]}",
  "${hashes[1]}"
]
for block_hash in block_hashes:
  selection_hash.update(bytes.fromhex(block_hash[2:] if block_hash.startswith("0x") else block_hash))

hexdigest = selection_hash.hexdigest()
winner = int(hexdigest, 16) % ${entries}
request = requests.get("${process.env.CONTEST_API}/v1/entries/${encodeURI(name)}")
entries = request.json()

print('winning entry index:', winner)
print('winning entry:', entries['entries'][winner]['entry'])`;

export default proofScript;
