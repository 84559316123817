import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Body = props => {
  const { children, ...rest } = props;
  return (
    <div className="tbody" {...rest}>
      {children}
    </div>
  );
};

const Row = props => {
  const { children, selected, ...rest } = props;
  return (
    <div className={`tr ${selected ? 'selected' : ''}`} {...rest}>
      {children}
    </div>
  );
};

const RowItem = props => {
  const { className, children, size, sortableValue, ...rest } = props;

  const itemClass = cx('td', size, className);

  return (
    <div className={itemClass} data-sort-value={sortableValue} {...rest}>
      {children}
    </div>
  );
};

export { Body, Row, RowItem };

// Body
Body.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

// Row
Row.defaultProps = { selected: false };

Row.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

// Row Item
RowItem.defaultProps = {
  className: '',
  sortableValue: '',
  size: 'min',
};

RowItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  size: PropTypes.oneOf(['min', '']),
  sortableValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};
