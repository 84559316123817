import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BoxRow, Title, Description, PillButton } from '@dragonchain-dev/dragon-kit';
import { initializeContest, getCount, getWinner } from '../store/contestSlice';
import { useEffectOnce } from '../utils/useEffectCustom';
import ContestRowCompleted from './ContestRowCompleted';
import ContestRowActive from './ContestRowActive';

const ContestRow = ({ name, prize, winnerKey, termsLink }) => {
  const dispatch = useDispatch();
  const contest = useSelector(state => state.contest.contests[name]);
  const [contestState, setContestState] = useState('');
  const [showResults, setShowResults] = useState(false);
  const epochTimeNow = Math.floor(Date.now() / 1000);
  const { schema } = contest || {};

  const calcState = (start, stop) => {
    if (epochTimeNow >= start && epochTimeNow <= stop) return 'active';
    if (start >= epochTimeNow) return 'future';
    if (epochTimeNow >= stop) return 'complete';
    return 'future';
  };

  useEffectOnce(() => {
    if (!schema || !schema.formname) dispatch(initializeContest({ name }));
  });

  useEffect(() => {
    if (!schema || !schema.start) return;
    setContestState(calcState(schema.start, schema.stop));
  }, [contest]);

  useEffect(() => {
    if (!schema) return;
    if (contestState === 'complete' && !contest.winner.selected) {
      dispatch(getWinner({ name }));
    }
    if (contestState === 'active') dispatch(getCount({ name }));
  }, [contestState]);

  const boxRowType = () => {
    switch (contestState) {
      case 'active':
        return 'open';
      case 'complete':
        if (showResults) return 'open';
        return 'highlight';
      default:
        return undefined;
    }
  };

  const contestHeaderDescription = () => {
    switch (contestState) {
      case 'active':
        return schema.prize || prize;
      case 'complete':
        return (
          <>
            {schema.prize || prize}
            <PillButton type="button" onClick={() => setShowResults(!showResults)}>
              {showResults ? 'Hide' : 'Results'}
            </PillButton>
          </>
        );
      default:
        return prize;
    }
  };

  return (
    <BoxRow type={boxRowType()}>
      <Title>{name}</Title>
      <Description>{contestHeaderDescription()}</Description>
      <>
        {contestState === 'complete' && showResults && (
          <ContestRowCompleted name={name} winnerKey={winnerKey} />
        )}
      </>
      <>{contestState === 'active' && <ContestRowActive name={name} termsLink={termsLink} />}</>
    </BoxRow>
  );
};

export default ContestRow;
