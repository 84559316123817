import React from 'react';
import { Block, Layout, Step, Title } from '@dragonchain-dev/dragon-kit';

const HowDragonchainWorks = () => {
  const { IMAGES_URI } = process.env;

  return (
    <Layout>
      <Block center>
        <Title>How Dragonchain Works</Title>
        {/* <p>
          A private blockchain leveraging decentralization and interoperability to ensure your data is both
          verifiable and secure.
        </p> */}
      </Block>
      <div className="steps">
        <Step
          reverse
          number={1}
          title="Create Your Private Blockchain"
          illustration={`${IMAGES_URI}/illustrations/how-dragonchain-works/step-1.svg`}
          line={`${IMAGES_URI}/illustrations/how-dragonchain-works/step-1-line.svg`}
        >
          Create your first blockchain without the hassle and added cost of blockchain engineers. You can spin
          up your node within minutes.
        </Step>
        <Step
          number={2}
          title="Upload a Smart Contract"
          illustration={`${IMAGES_URI}/illustrations/how-dragonchain-works/step-2.svg`}
          illustrationClass="up-85 tablet-landscape-up-60 phone-down-20"
          stepClass="padding-bottom-110"
        >
          Implemented using container architecture for portability and auto-scaling, you are able to move
          business logic to your private blockchain and without the need to learn new languages.
        </Step>
        <Step
          number={3}
          title="Post a Transaction"
          illustration={`${IMAGES_URI}/illustrations/how-dragonchain-works/step-3.svg`}
          stepClass="padding-bottom-110"
          reverse
        >
          Leverage Dragonchain&apos;s lightweight SDKs to post transactions to your private blockchain. Open
          source SDKs are available for NodeJS, Python, Go, and Dart.
        </Step>
        <Step
          number={4}
          title="Payload Sent to Private Data Store"
          illustration={`${IMAGES_URI}/illustrations/how-dragonchain-works/step-4.svg`}
          illustrationClass="phone-left-5"
          stepClass="tablet-landscape-padding-bottom-0"
        >
          Data is kept off-chain in a secure private data store, ensuring your business can remain compliant
          with personal information protection laws (GDPR, CCPA).
        </Step>
        <Step
          number={5}
          title="Thumbprint Stored On-Chain"
          illustration={`${IMAGES_URI}/illustrations/how-dragonchain-works/step-5.svg`}
          illustrationClass="phone-left-5"
          line={`${IMAGES_URI}/illustrations/how-dragonchain-works/step-5-line.svg`}
          bottom
        >
          A hash is used as proof of the data&apos;s existence and stored on-chain, while your data remains
          private.
        </Step>
        <Step
          reverse
          number={6}
          title="Network Consensus"
          illustration={`${IMAGES_URI}/illustrations/how-dragonchain-works/step-6.svg`}
          line={`${IMAGES_URI}/illustrations/how-dragonchain-works/step-6-line.svg`}
        >
          Your private blockchain connects to a larger network of chains (Dragon Net) adding an additional
          level of protection to your private transactions.
        </Step>
        <Step
          full
          number={7}
          title="Interchain to Public Blockchain"
          illustration={`${IMAGES_URI}/illustrations/how-dragonchain-works/step-7.svg`}
        >
          Only Dragonchain can provide the interoperability with Bitcoin, Ethereum, Ethereum Classic, and
          Binance Chain required to secure your transactions. Our patented interchain network provides you
          with added transparency and security, always knowing your data is held in your private data store.
        </Step>
      </div>
    </Layout>
  );
};

export default HowDragonchainWorks;
