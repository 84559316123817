import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { Button, PillButton } from '@dragonchain-dev/dragon-kit';

const TypeForm = ({ buttonType, label, url }) => {
  const typeformRef = useRef();

  const Tag = buttonType === 'Pill Button' ? PillButton : Button;

  return (
    <>
      <ReactTypeformEmbed style={{ zIndex: '-999' }} ref={typeformRef} popup {...{ url }} />
      <Tag large type="button" onClick={() => typeformRef.current.typeform.open()}>
        {label}
      </Tag>
    </>
  );
};

export default TypeForm;

TypeForm.defaultProps = {
  buttonType: 'Pill Button',
};

TypeForm.propTypes = {
  buttonType: PropTypes.oneOf(['Button', 'Pill Button']),
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

/* ReactTypeformEmbed 

  PropsTypes
  {
    style: _propTypes2.default.object,
    url: _propTypes2.default.string.isRequired,
    popup: _propTypes2.default.bool,
    hideHeaders: _propTypes2.default.bool,
    hideFooter: _propTypes2.default.bool,

    // Widget options
    opacity: _propTypes2.default.number,
    buttonText: _propTypes2.default.string,

    // Popup options
    mode: _propTypes2.default.string,
    autoOpen: _propTypes2.default.bool,
  };

  Defaults
  {
    style: {},
    popup: false,
    hideHeaders: false,
    hideFooter: false,
    onSubmit: function onSubmit() {},

    // Widget options
    opacity: 100,
    buttonText: 'Start',

    // Popup options
    mode: 'popup', // options: "popup", "drawer_left", "drawer_right"
    autoOpen: false,
    autoClose: 5
  }
*/
