import React, { useEffect, useState } from 'react';
import Prism from 'prismjs';
import CodeNav from './CodeNav';
import script from '../utils/scripts';
import '../assets/styles/vendors/prism.css';

const CodeExamples = () => {
  const [type, setType] = useState('javascript');
  useEffect(() => Prism.highlightAll());

  return (
    <div className="code-examples">
      <div className="inner">
        <CodeNav active={type} setActive={setType} />
        <div className="code-content">
          <div className="code-content-header">
            <div className="title">Post a Dragonchain Transaction</div>
            <div className="lang">{type}</div>
          </div>
          <div className="code-example">
            <pre>
              <code className={`language-${type}`}>{script[type]}</code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeExamples;
