const script = {
  javascript: `const { createClient } = require('dragonchain-sdk');

const client = await createClient();

client.createTransaction({
  transactionType: 'loyalty',
  payload: {
    'attributes': {
      'customerId': 842194, 'loyaltyLevel': 'Gold'
    },
    'rewardMessage': '50% off reservation'
  }
});`,

  python: `import dragonchain_sdk

# Create the Dragonchain client
client = dragonchain_sdk.create_client()

# Create a Dragonchain transaction
txn = client.create_transaction(
  transaction_type='loyalty',
  payload={
    'attributes': {
      'customerId': 842194, 'loyaltyLevel': 'Gold'
    },
    'rewardMessage': '50% off reservation'
  }
)`,

  dart: `import 'package:dragonchain_sdk/dragonchain_sdk.dart';
  
DragonchainClient client = DragonchainClient.createClient();

var transaction = await client.createTransaction('loyalty', 
  {
    'attributes': {
      'customerId': 842194, 'loyaltyLevel': 'Gold'
    },
    'rewardMessage': '50% off reservation'
  }
);`,

  go: `import (
  "fmt"
  "net/http"

  "github.com/dragonchain/dragonchain-sdk-go"
)

httpClient := &http.Client{}
myCredentials := dragonchain.NewCredentials()
client := dragonchain.NewClient(myCredentials, 'https://chain-31s2-13d1.api.dragonchain.com', httpClient)
transaction := CreateTransaction{
  Version: '1',
  TransactionType: 'loyalty'
}
call, err := await client.CreateTransaction(&transaction)`,
};

export default script;
