import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { Button } from '@dragonchain-dev/dragon-kit';

const { IMAGES_URI } = process.env;
const sdkDartSVG = `${IMAGES_URI}/icons/dart.svg`;
const sdkNodeSVG = `${IMAGES_URI}/icons/nodejs.svg`;
const sdkPythonSVG = `${IMAGES_URI}/icons/python.svg`;
const sdkGoSVG = `${IMAGES_URI}/icons/go.svg`;

const CodeNav = ({ active, setActive }) => {
  return (
    <div className="code-nav">
      <Button
        type="button"
        className={cx(active === 'javascript' ? 'active' : 'x')}
        onClick={() => setActive('javascript')}
      >
        <ReactSVG src={sdkNodeSVG} />
      </Button>
      <Button
        type="button"
        className={cx(active === 'python' ? 'active' : 'x')}
        onClick={() => setActive('python')}
      >
        <ReactSVG src={sdkPythonSVG} />
      </Button>
      <Button type="button" className={cx(active === 'go' ? 'active' : 'x')} onClick={() => setActive('go')}>
        <ReactSVG src={sdkGoSVG} />
      </Button>
      <Button
        type="button"
        className={cx(active === 'dart' ? 'active' : 'x')}
        onClick={() => setActive('dart')}
      >
        <ReactSVG src={sdkDartSVG} />
      </Button>
    </div>
  );
};

export default CodeNav;

CodeNav.defaultProps = {
  active: 'javascript',
};

CodeNav.propTypes = {
  active: PropTypes.oneOf(['nodejs', 'python', 'go', 'dart']),
};
