import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Button } from '@dragonchain-dev/dragon-kit';
import { Thumbsup } from '@dragonchain-dev/dragon-kit-icons';
import Input from './Input';

const FORM_URL =
  'https://docs.google.com/forms/u/4/d/e/1FAIpQLSdXVBbwdxJSH75HEcgjrApXHuv1ppHtKXTaxEOko0swZbhRrg/formResponse';

const FormForbes = () => {
  const methods = useForm();
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async data => {
    methods.clearError();

    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(`entry.${key}`, data[key]));
    fetch(FORM_URL, { method: 'POST', mode: 'cors', body: formData });

    setSubmitted(true);
    methods.reset();
  };

  if (submitted)
    return (
      <div className="form">
        <div className="success-message">
          <Thumbsup className="icon" color="white" />
          <p>Thank you, we will contact you soon</p>
        </div>
      </div>
    );

  return (
    <div className="form">
      <FormContext {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} noValidate>
          <div className="fields">
            <Input required name="1886459197" placeholder="First name..." />
            <Input required name="1595720733" placeholder="Last name..." />
          </div>
          <div className="fields">
            <Input required type="email" name="1342832425" placeholder="Email..." />
            <Input required name="516323176" placeholder="Company..." />
          </div>
          <div className="actions">
            <Button outline type="submit">
              Contact Me
            </Button>
          </div>
        </form>
      </FormContext>
    </div>
  );
};

export default FormForbes;
