import React from 'react';
import PropTypes from 'prop-types';

const Table = props => {
  const { className, children, headers, ...rest } = props;
  const classes = `table ${className}`;
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default Table;

Table.defaultProps = {
  className: '',
};

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
