import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useFormContext } from 'react-hook-form';

const Input = ({ name, placeholder, type, required }) => {
  const { register, clearError, errors } = useFormContext();
  const options = required ? { required: "Can't be blank" } : {};
  const classes = cx('field', errors[name] ? 'error' : '');

  return (
    <div className={classes}>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        onFocus={() => clearError()}
        aria-invalid={errors[name] ? 'true' : 'false'}
        ref={register(options)}
      />
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  required: false,
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'email']),
};

export default Input;
