import React from 'react';
import PropTypes from 'prop-types';
import { Column, Detail, Row, LinkTo } from '@dragonchain-dev/dragon-kit';

const Feature = ({ Icon, title, body, link }) => {
  const props = {
    icon: !!Icon && <Icon />,
    title,
    footer: link && (
      <LinkTo type="route" to={link}>
        Learn More
      </LinkTo>
    ),
  };
  return (
    <Column>
      <Detail {...props}>{body}</Detail>
    </Column>
  );
};

const FeatureRow = ({ features }) => {
  return (
    <Row gap="large" columnGap="medium" span={4} tabletPortraitSpan={6}>
      {features.map(({ Icon, title, body, link }) => (
        <Feature {...{ Icon, title, body, link }} />
      ))}
    </Row>
  );
};

export default FeatureRow;

FeatureRow.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.node.isRequired,
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
